.large, .small {
  box-shadow: inset 0px -10px 20px #250f08;

  & > :global(.ui.grid) {
    height: 100%; } }

.none {
  > :global(.ui.header:not(.sub)) {
    color: white;
    font-family: carta-marina, serif !important;
    font-size: 1.5rem !important; }

  background: url("../images/dark_wall.png");
  padding: 1.5rem; }

.menuButton {
  position: absolute;
  top: 1rem;
  left: 1rem; }

.title {
  background: #381709ab;
  padding: 2rem !important;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);

  > :global(.ui.header:not(.sub)) {
    font-family: carta-marina, serif !important;
    font-size: 2.5rem !important;
    margin: 0;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5); }

  > :global(.ui.header) {
    color: #FFF !important; }

  > .callout {
    color: #f3e2d9; } }

.callout {
  font-family: broadsheet, serif;
  margin-top: 1em; }

$primary-font-color: #3a2c2c;
$secondary-font-color: #4e4e4e;

.container {
  color: $primary-font-color;
  font-family: mr-eaves-modern, serif;
  font-size: 14pt;
  line-height: 2;

  h1, h2, h3, h4, h5, h6, :global(.ui.header) {
    color: $primary-font-color;
    font-family: broadsheet;

    &:global(.sub) {
      color: lighten($primary-font-color, 30%); } }

   :global(.ui.segment) {
    color: $secondary-font-color;

    h1, h2, h3, h4, h5, h6, :global(.ui.header) {
      color: $secondary-font-color;
      text-shadow: none;

      &:global(.sub) {
        opacity: 0.75; } } }

   :global(.ui.dimmable.image) {
    h1 {
      color: #FFF; } } }

.hidden {
  display: none !important; }

.header {
  font-family: mr-eaves-modern, serif;
  font-size: 14pt;
  position: relative; }

.nav {
  padding-top: 2rem;
  margin-bottom: 2rem;

  > :global(.ui.menu) {
    background: none;
    border: none;
    box-shadow: none;
    font-size: 1.15em;
    font-variant: small-caps;
    justify-content: center;
    transition: opacity 0.5s;

    > :global(.item) {
      opacity: 0.75;
      transition: opacity 0.5s;

      &:hover {
        opacity: 1; }

      a {
        color: $primary-font-color; } } } }
